import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import EmbarkOnBannerData from '../Data/EmbarkOnBannerData.json'
import styled from 'styled-components'

const AppEmbarkOnBannerWapper = styled.div`
  .EmbarkOnSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .EmbarkOnImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .EmbarkOnHeading {
    max-width: 850px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .EmbarkOnPara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .DateEmbarkOnPara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .EmbarkOnBannerImage {
    width: 100%;
    height: auto;
    border-radius: 18px;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppEmbarkOnBanner = () => {
  return (
    <AppEmbarkOnBannerWapper>
      {EmbarkOnBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              Para={data.Leadpara}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaStyle='DisplayNone'
              BannerHeading='EmbarkOnHeading'
              BannerPara='EmbarkOnPara'
              DateBannerPara='DateEmbarkOnPara'
              BannerImageSection='EmbarkOnImageSection'
              BannerImage='EmbarkOnBannerImage'
              BannerSection='EmbarkOnSection'
            />
          </div>
        )
      })}
    </AppEmbarkOnBannerWapper>
  )
}
