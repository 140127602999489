import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import EmbarkOnRestData from '../Data/EmbarkOnRestData.json'

const AppEmbarkOnRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContentSectionEmailEmbarkOn {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .LeadParaStyleEmbarkOnOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleEmbarkOn {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppEmbarkOnRest = () => {
  return (
    <AppEmbarkOnRestWapper>
      <div>
        {EmbarkOnRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContentSectionEmailEmbarkOn'>
                <ReUsePtag para={data.LeadParaTwo} paraStyle='LeadParaStyleEmbarkOnOne' />
                <ReUsePtag para={data.LeadParaThree} paraStyle='LeadParaStyleEmbarkOn' />
                <ReUsePtag para={data.LeadParaFour} paraStyle='LeadParaStyleEmbarkOn' />
                <ReUsePtag para={data.LeadParaFive} paraStyle='LeadParaStyleEmbarkOn' />
              </div>
            </div>
          )
        })}
      </div>
    </AppEmbarkOnRestWapper>
  )
}
